<template>
  <div class="zy-title">
    <img
      v-if="$slots.default"
      src="../../assets/icons/icon_title.png"
    />
    <div class="zy-title-text">
      <slot></slot>
    </div>
    <slot name="more">
      <div
        v-if="$listeners.more"
        class="zy-title-more"
        @click="showMore"
      >
        查看更多 >>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    more: Function, //查看更多响应
  },
  methods: {
    showMore() {
      if (this.$listeners.more) {
        this.$emit("more")
      }
    },
  },
}
</script>

<style lang="less" scoped>
.zy-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  height: 44px;
  box-sizing: border-box;
  & > img {
    width: 12px;
    height: 18px;
    margin-right: 10px;
  }
  &-text {
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    width: 100%;
  }
  &-more {
    flex-shrink: 0;
    font-size: 14px;
    color: #66bbf9;
    cursor: pointer;
  }
}
</style>
