<template>
  <div class="zy-empty">
    <img src="../../assets/images/img_empty.png" />
    <div>暂无数据</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.zy-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  line-height: 21px;
  & > img {
    width: 200px;
    height: 120px;
    margin-bottom: 30px;
  }
}
</style>
