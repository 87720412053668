var QmodelOption = function () {
  this.API_URL = "http://www.qmodel.cn:4566/api";
  this.FILE_SERVE_URL =
    "http://www.qmodel.cn:9107/resourceHandle/file/downloadByPath";
  this.FILE_SERVE_URL_UP =
    "http://www.qmodel.cn:9107/resourceHandle/ng/upload?path=";
  this.FILE_SERVE_URL2 = "http://www.qmodel.cn/file";
  this.DemoUser = "13726295713";
  this.DemoPwd = "xing921217";
  this.DefaultToken = null;
};

var QmodelOption1 = function () {
  this.API_URL = "http://localhost:4566/api";
  this.FILE_SERVE_URL =
    "http://localhost:9107/resourceHandle/file/downloadByPath";
  this.FILE_SERVE_URL_UP =
    "http://localhost:9107/resourceHandle/ng/upload?path=";
  this.FILE_SERVE_URL2 = "http://localhost:4567/file";
  this.DemoUser = "20191205";
  this.DemoPwd = "20191205";
};

export { QmodelOption };
