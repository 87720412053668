<template>
  <!-- 为保证页面css纯净，独立使用iframe调用 -->
  <!-- <iframe src="/qmodel?url=xxxx"></iframe> -->
  <div class="iframe-bim">
    <div
      class="iframe-bim-center"
      v-if="modelName && modelName.length"
    >
      <zy-bim
        :modelName="modelName"
        :pubPath="pubPath"
        :key="modelName"
      ></zy-bim>
    </div>
    <zy-empty v-else></zy-empty>
    <div
      class="iframe-bim-select"
      v-if="resources.length && resources[0].label != -1"
    >
      <zy-title>模型列表</zy-title>
      <zy-select
        v-model="current_resources_index"
        :list="resources"
        @change="changeResources"
      >
        <template slot-scope="{ row }">
          {{ `${row.label}${unit}` }}
        </template>
      </zy-select>
    </div>
  </div>
</template>

<script>
import ZyBim from "./components/zy-bim"
import ZySelect from "./components/zy-select"
import ZyTitle from "./components/zy-title"
import ZyEmpty from "./components/zy-empty"
import axios from "axios"
export default {
  name: "cache-bim",
  components: { ZyBim, ZySelect, ZyTitle, ZyEmpty },
  data() {
    return {
      current_resources_index: 0,
      modelName: "",
      pubPath: "",
      resources: [],
      unit: "",
    }
  },
  mounted() {
    this.unit = this.$route.query.unit
    this.initData()
  },
  methods: {
    async initData() {
      await this.checkoutResources()
      this.changeResources()
    },
    changeResources() {
      if (!this.resources.length) {
        this.modelName = null
        this.pubPath = null
        return
      }
      const resources = this.resources[this.current_resources_index]
      console.log(resources)
      this.pubPath = resources.pubPath
      this.modelName = resources.modelName
    },
    splitUrl(url) {
      const list = url.split("/")
      const modelName = list[list.length - 1]
      if (modelName == "undefined") {
        return {
          pubPath: null,
          modelName: null,
        }
      }
      const pubPath = url.replace(`/${modelName}`, "")
      return {
        pubPath,
        modelName,
      }
    },
    //检查路径下存在的资源
    async checkoutResources() {
      return new Promise(async (resolve, reject) => {
        const url = this.$route.query.url
        try {
          const result = await this.requestResources(url)
          if (result) {
            this.resources.push({
              label: "-1",
              ...this.splitUrl(url),
            })
            return resolve(true)
          }
        } catch (err) {
          //异常任务根目录没有模型，开始嗅探楼栋
          for (let i = 1; i < 51; i++) {
            try {
              const subdirectories = `${url}/${i}`
              const sub_result = await this.requestResources(subdirectories)
              //如果该目录下存在资源，则不继续嗅探了。
              if (sub_result) {
                this.resources.push({
                  label: i,
                  pubPath: url,
                  modelName: `${i}`,
                })
              }
            } catch (err) {
              break
            }
          }
          resolve(true)
        }
      })
    },
    //检查资源是否存在
    requestResources(url) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${url}/5d.gz`)
          .then((res) => {
            if (res.status == 200) {
              return resolve(true)
            }
            reject(false)
          })
          .catch((err) => {
            reject(false)
          })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.iframe-bim {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  &-center {
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    bottom: 0;
  }
  &-select {
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 10;
    .zy-title + .zy-select {
      margin-top: 10px;
    }
  }
}
</style>
