<template>
  <div class="bim-qmodel">
    <qmviewer
      :modelName="modelName"
      :pubPath="pubPath"
      :isCompont="isCompont"
      :isLocal="isLocal"
      ref="qmviewer"
      @callFather_loaded="callFather_loaded"
    >
    </qmviewer>
  </div>
</template>

<script>
import qmviewer from "./qmviewer.vue"
export default {
  components: { qmviewer },
  data() {
    return {
      isLocal: true,
      isCompont: true,
    }
  },
  props: {
    modelName: String,
    pubPath: {
      type: String,
      default: "",
    },
  },
  methods: {
    callFather_loaded() {},
  },
}
</script>

<style lang="less">
.bim-qmodel {
  width: 100%;
  height: calc(100% - 30px);
  transform: none;
  resize: none;
}
#container {
  background: transparent !important;
}
#qmodel-div {
  top: 20px !important;
  transform: none;
  transform-style: flat;
}
#container {
  background: transparent !important;
}
</style>
