<template>
  <div class="zy-select">
    <div
      class="zy-select-left"
      v-if="showTools"
      @click="scrollTo(-3)"
    >
      <i class="el-icon-caret-left"></i>
    </div>
    <div
      class="zy-select-list"
      ref="list"
    >
      <div
        :class="['zy-select-item', index == value ? 'zy-select-item-hl' : '']"
        v-for="(item, index) in list"
        :key="index"
        @click="clickItem(item, index)"
      >
        <slot :row="item">{{ item.label }}</slot>
      </div>
    </div>
    <div
      class="zy-select-right"
      v-if="showTools"
      @click="scrollTo(3)"
    >
      <i class="el-icon-caret-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 66,
    }
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showTools() {
      return this.list.length > 6
    },
  },
  methods: {
    clickItem(item, index) {
      this.$emit("input", index)
      this.$nextTick(() => {
        this.$emit("change", item, index)
      })
    },
    scrollTo(idx) {
      const dom = this.$refs.list
      const scrollLeft = dom.scrollLeft
      dom.scrollTo({
        left: scrollLeft + this.step * idx,
        behavior: "smooth",
      })
    },
  },
}
</script>

<style lang="less" scoped>
.icon {
  font-size: 24px;
  color: #19fbff;
}
.zy-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 534px;
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  &-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 56px;
    height: 34px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    line-height: 18px;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    margin-right: 10px;
    &-hl {
      font-weight: bold;
      background: linear-gradient(143deg, rgba(0, 233, 255, 0.46) 0%, rgba(28, 47, 99, 0) 100%);
      border: 1px solid;
      border-image: linear-gradient(135deg, rgba(51, 216, 250, 1), rgba(24, 123, 205, 0)) 1 1;
    }
  }
  &-left {
    flex-shrink: 0;
    background: linear-gradient(143deg, rgba(0, 233, 255, 0.46) 0%, rgba(28, 47, 99, 0) 100%);
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(51, 216, 250, 1), rgba(24, 123, 205, 0)) 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-right: 10px;
    & > i {
      .icon;
    }
  }
  &-right {
    flex-shrink: 0;
    background: linear-gradient(180deg, rgba(0, 233, 255, 0.46) 0%, rgba(28, 47, 99, 0) 100%);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(51, 216, 250, 1), rgba(24, 123, 205, 0)) 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-left: 10px;
    & > i {
      .icon;
    }
  }
}
</style>
