<template>
  <div
    id="qmodel-div"
    ref="qmodel-div"
    class="main"
    style="height: 100%; width: 100%; left: 0%; position: absolute"
  >
    <div class="qm-loading">
      <div class="qm-loading-gif"></div>
    </div>
    <div
      id="qrcode1"
      ref="qrcode1"
      style="display: none"
    ></div>

    <div
      id="progressDiv"
      ref="progressDiv"
      class="container_progress"
    >
      <span class="progressBar">
        <div
          id="progressFill"
          ref="progressFill"
        ></div>
      </span>
      <span
        id="progressInfo"
        ref="progressInfo"
        class="progressText"
      ></span>
      <!-- <span id="percentage">0%</span> -->
    </div>
    <div
      id="viewcube"
      ref="viewcube"
    ></div>
    <div
      id="container"
      ref="container"
      style="width: 100%; height: 100%; position: absolute; z-index: 6"
    >
      <canvas
        id="canvasmain"
        ref="canvasmain"
        style="left: 0px; width: 100%; height: 100%; position: absolute; z-index: 1"
      ></canvas>
    </div>
    <div
      id="containersel"
      ref="containersel"
      style="width: 100%; height: 100%; position: absolute; overflow: hidden; z-index: 7; pointer-events: none"
    >
      <canvas
        id="canvassel"
        ref="canvassel"
      ></canvas>
    </div>
    <div
      id="tipInfo"
      ref="tipInfo"
      style="top: 200px; left: 382.5px; z-index: 10; position: absolute; padding: 3px 5px; background: rgb(71, 78, 96); font-size: 14px; margin: 0px auto; text-align: center; width: 300px; height: auto; color: rgb(255, 255, 255); opacity: 0.8; display: none"
    >
      tipinfo
    </div>
    <input
      type="range"
      min="0"
      max="100"
      value="0"
      class="slider"
      id="myRange"
      ref="myRange"
      style="z-index: 9; background-color: coral; position: absolute; bottom: 20%; left: 50%; top: 30%; transform: translate(-50%, -50%); width: 300px; color: black; display: none"
    />

    <div
      class="qm-toolbar qm-toolbar-bottom"
      style="display: none"
      title="主菜单"
      id="qmodel_toolbar"
      ref="qmodel_toolbar"
    >
      <div
        class="qm-button qmd-home iconfont icon-bim_bumanshitu"
        title="Home"
      ></div>
      <div
        class="qm-button qmd-zoomrect iconfont icon-bimgis_kuangxuan"
        title="框选"
      ></div>
      <div
        class="qm-button qmd-measure iconfont icon-bimgis_suanliang"
        title="测量"
      ></div>
      <!-- <div class="qm-button qmd-lookat" title="炸开"></div> -->
      <!-- <div class="qm-button qm-toolbar-button qmd-sectionbox" title="剖切">
			<div class="qm-sub-toolbar" title="剖切">
				<div class="qm-button qmd-section-axial" title="轴向剖切"></div>
				<div class="qm-button qmd-sectionbox1" title="剖切盒"></div>
			</div>
		</div> -->
      <div
        class="qm-button qmd-section-axial iconfont icon-bim_Yzhou"
        title="轴向剖切"
      ></div>
      <!-- <div class="qm-button qm-toolbar-button qmd-firstperson" title="漫游模式">
            <div class="qm-sub-toolbar" title="漫游">
                <div class="qm-button qmd-walk" title="漫游"></div>
                <div class="qm-button qmd-circlelook" title="旋转观察"></div>
            </div>
        </div> -->
      <div
        class="qm-button qmd-walk iconfont icon-bim_diyirencheng"
        title="漫游模式"
      ></div>
      <div
        class="qm-button qmd-map iconfont icon-dingweiditu"
        title="小地图"
      ></div>
      <div
        class="qm-button qm-toolbar-button qmd-dingweiM iconfont icon-pizhu"
        title="标记"
      >
        <div
          class="qm-sub-toolbar"
          title="标记"
        >
          <div
            class="qm-button qmd-dingwei iconfont icon-bimgis_biaoji"
            title="标记"
          ></div>
          <div
            class="qm-button qmd-3D iconfont icon-wenzi"
            title="3D标注"
          ></div>
        </div>
      </div>
      <div
        class="qm-button qmd-properties iconfont icon-xinxichakan"
        title="构件详情"
      ></div>
      <!-- <div class="qm-button qmd-plan iconfont icon-qm_jindufenxi" style="display: none;" title="进度计划"></div> -->
      <div
        class="qm-button qmd-view iconfont icon-shikou"
        title="相机书签"
      ></div>
      <div
        class="qm-button qmd-material iconfont icon-gis_biaohui"
        title="材质库"
      ></div>
      <!-- <div class="qm-button qmd-extmodel iconfont icon-chuanganqi" style="display: none;" title="设备管理"></div> -->
      <div
        class="qm-button qmd-information iconfont icon-jibenxinxi"
        title="基本信息"
      ></div>
      <div
        class="qm-button qmd-set iconfont icon-shezhi"
        title="设置"
      ></div>
    </div>

    <!-- <div
      class="qm-toolbar qm-tree-toolbar"
      title="模型列表"
      id="qmodel_model_btn"
      ref="qmodel_model_btn"
    >
      <div
        class="qm-button qmd-tree iconfont icon-project_management"
        title="模型列表"
      ></div>
    </div> -->
    <div
      class="qm-toolbar qm-tree-toolbar"
      style="display: none; margin-top: 20px; opacity: 0"
      title="目录树"
      id="qmodel_tree_btn"
      ref="qmodel_tree_btn"
    >
      <div
        class="qm-button qmd-tree iconfont icon-xianshicaidan"
        title="目录树"
      ></div>
    </div>
    <div
      id="divTree"
      ref="divTree"
      class="qm-panel qm-has-title qm-sizable"
      style="display: none; left: 10px; top: 90px; max-height: 800px; min-height: 400px; width: 534px; overflow: auto"
    >
      <!-- <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        目录树
      </div> -->
      <div class="qm-panel-body">
        <div
          class="qm-panel-header"
          id="multTreeDiv"
          ref="multTreeDiv"
          style="display: none"
        >
          <div
            class="qm-tree-header"
            style="margin-top: -12px"
          >
            <div class="qm-tree-tab">
              <div class="qm-tabs qm-tabs-tree threeTabs">
                <ul class="qm-tabs-list">
                  <li
                    class="qm-tabs-option active"
                    id="tab-file"
                    ref="tab-file"
                    title="文件"
                  >
                    文件
                  </li>
                  <li
                    class="qm-tabs-option"
                    id="tab-zy"
                    ref="tab-zy"
                    title="专业"
                  >
                    专业
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divBfTree"
          ref="divBfTree"
          style="max-height: 700px; min-height: 300px"
        ></div>
        <div
          class="qm-panel-container qm-scroll-bar"
          style="display: none"
          id="divBfTree2"
          ref="divBfTree2"
        ></div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>

    <!-- 以下是属性部分 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-panel"
      ref="property-panel"
      style="display: none; right: 20px; top: 10px; width: 534px; height: 588px"
    >
      <!-- <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        构件详情
      </div> -->
      <zy-title>构件详情</zy-title>
      <div class="qm-panel-body">
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divProperty"
          ref="divProperty"
        >
          <table
            class="qm-table"
            id="tableProperty"
            ref="tableProperty"
          ></table>
        </div>
      </div>
    </div>
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-modify-dialog"
      ref="property-modify-dialog"
      style="display: none; left: 50%; transform: translate(-50%, -50%); top: 300px; width: 300px; height: 120px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        编辑属性
      </div>
      <div class="qm-panel-body">
        <table class="qm-table">
          <tr class="qm-group-content">
            <td
              class="qm-key"
              id="pty-modify-key"
              ref="pty-modify-key"
            >
              属性名称
            </td>
            <td class="qm-value">
              <input
                id="pty-modify-value"
                ref="pty-modify-value"
                type="text"
              />
            </td>
          </tr>
        </table>

        <div
          class="qm-button-font"
          id="property-modify-add-save"
          ref="property-modify-add-save"
        >
          保存
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>

    <!-- 以下是漫游说明 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-walking-dialog"
      ref="property-walking-dialog"
      style="display: none; right: 0px; bottom: 10px; width: 100px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        漫游说明
      </div>
      <div class="qm-panel-body">
        <table class="qm-table">
          <tr class="qm-group-content">
            <td
              class="qm-key"
              style="width: 50%"
            >
              z
            </td>
            <td class="qm-value">
              位置回退<input
                id="iwalkstep"
                ref="iwalkstep"
                style="width: 50px; display: none"
                value="0"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">+</td>
            <td class="qm-value">加速</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">-</td>
            <td class="qm-value">减速</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">W</td>
            <td class="qm-value">前进</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">S</td>
            <td class="qm-value">后退</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">A</td>
            <td class="qm-value">左移</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">D</td>
            <td class="qm-value">右移</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">R</td>
            <td class="qm-value">上移</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">F</td>
            <td class="qm-value">下移</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">=</td>
            <td class="qm-value">碰撞</td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">/</td>
            <td class="qm-value">重力</td>
          </tr>
        </table>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>

    <!-- 以下是设置 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-set-dialog"
      ref="property-set-dialog"
      style="display: none; left: 50%; transform: translate(-50%, 50%); width: 300px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        <div
          class="qm-button-font"
          id="property-set1"
          ref="property-set1"
        >
          实时参数
        </div>
        <div
          class="qm-button-font"
          id="property-set2"
          ref="property-set2"
        >
          初始化参数
        </div>
      </div>
      <div class="qm-panel-body">
        <table
          class="qm-table"
          id="set-table1"
          ref="set-table1"
        >
          <tr class="qm-group-content">
            <td class="qm-key">背景色</td>
            <td class="qm-value">
              <input
                type="color"
                name="set-v1"
                id="set-v1"
                ref="set-v1"
              />
              or 透明<input
                id="set-ck6"
                ref="set-ck6"
                type="checkbox"
              />
              <select
                id="set-sel2"
                ref="set-sel2"
              >
                <option value="空">空</option>
                <option value="0.jpg">0</option>
                <option value="1.jpg">1</option>
                <option value="2.jpg">2</option>
                <option value="3.jpg">3</option>
                <option value="4.jpg">4</option>
                <option value="5.jpg">5</option>
                <option value="6.jpg">6</option>
                <option value="7.gif">7</option>
              </select>
            </td>
          </tr>
          <!-- 设置主题色只是用来示意，不采用的话可以屏蔽该行<tr> -->
          <tr class="qm-group-content">
            <td class="qm-key">主题色</td>
            <td class="qm-value">
              <div
                class="qm-button-color"
                value="1"
                style="background-color: rgb(0, 0, 0)"
              ></div>
              <div
                class="qm-button-color"
                value="2"
                style="background-color: rgb(15 49 126)"
              ></div>
              <div
                class="qm-button-color"
                value="3"
                style="background-color: rgb(161, 21, 21)"
              ></div>
              <div
                class="qm-button-color"
                value="4"
                style="background-color: rgb(81, 188, 212)"
              ></div>
              <div
                class="qm-button-color"
                value="5"
                style="background-color: rgb(255, 111, 1)"
              ></div>
              <div
                class="qm-button-color"
                value="6"
                style="background-color: rgb(255, 255, 255)"
              ></div>
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">环境亮度(0.1~1)</td>
            <td class="qm-value">
              <div class="qm-section-range">
                <div class="qm-range">
                  <input
                    id="set-v3"
                    ref="set-v3"
                    type="range"
                    step="0.1"
                    min="0"
                    max="1"
                    value="0.9"
                    style="background: linear-gradient(to right, rgb(102, 102, 102) 0%, rgb(102, 102, 102) 50%, rgb(102, 102, 102) 50%, rgb(102, 102, 102) 100%)"
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr
            class="qm-group-content"
            style="display: none"
          >
            <td class="qm-key">环境光对比度(1~7)</td>
            <td class="qm-value">
              <input
                id="set-v5"
                ref="set-v5"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">单击对焦</td>
            <td class="qm-value">
              <input
                id="set-ck-clickCenter"
                ref="set-ck-clickCenter"
                type="checkbox"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">锚定缩放</td>
            <td class="qm-value">
              <input
                id="set-ck-zoomLock"
                ref="set-ck-zoomLock"
                type="checkbox"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">开启阻尼</td>
            <td class="qm-value">
              <input
                id="set-ck-damping"
                ref="set-ck-damping"
                type="checkbox"
              />
            </td>
          </tr>
          <tr
            class="qm-group-content"
            style="display: none"
          >
            <td class="qm-key">开启直射光</td>
            <td class="qm-value">
              <input
                id="set-ck2"
                ref="set-ck2"
                type="checkbox"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">直射光强度(0.1~1)</td>
            <td class="qm-value">
              <div class="qm-section-range">
                <div class="qm-range">
                  <input
                    id="set-v4"
                    ref="set-v4"
                    type="range"
                    step="0.1"
                    min="0"
                    max="1"
                    value="0.9"
                    style="background: linear-gradient(to right, rgb(102, 102, 102) 0%, rgb(102, 102, 102) 50%, rgb(102, 102, 102) 50%, rgb(102, 102, 102) 100%)"
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">开启阴影</td>
            <td class="qm-value">
              <input
                id="set-ck5"
                ref="set-ck5"
                type="checkbox"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key"></td>
            <td class="qm-value">
              <input
                id="btn-setDefaultView"
                ref="btn-setDefaultView"
                value="当前视口存为封面"
                type="button"
              />
            </td>
          </tr>
        </table>

        <table
          class="qm-table"
          id="set-table2"
          ref="set-table2"
          style="display: none"
        >
          <tr class="qm-group-content">
            <td class="qm-key">环境</td>
            <td class="qm-value">
              <select
                id="set-sel1"
                ref="set-sel1"
              >
                <option value="0">无</option>
                <option value="1">大海</option>
                <option value="2">沙漠</option>
                <option value="3">天空</option>
                <option value="4">平原</option>
              </select>
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">纹理效果</td>
            <td class="qm-value">
              <select
                id="sel-mat"
                ref="sel-mat"
              >
                <option value="0">真实</option>
                <option value="1">着色</option>
              </select>
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">纹理明亮度(0~1)</td>
            <td class="qm-value">
              <input
                id="set-mat"
                ref="set-mat"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">使用反光材质，反光率(1~100)</td>
            <td class="qm-value">
              <input
                id="set-ck4"
                ref="set-ck4"
                type="checkbox"
              /><input
                id="set-v9"
                ref="set-v9"
                type="text"
              />
            </td>
          </tr>

          <tr class="qm-group-content">
            <td class="qm-key">显示二维码</td>
            <td class="qm-value">
              <input
                id="set-ck3"
                ref="set-ck3"
                type="checkbox"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">漫游旋转速度(0.1~0.9)</td>
            <td class="qm-value">
              <input
                id="set-v6"
                ref="set-v6"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">漫游前进速度(10~100)</td>
            <td class="qm-value">
              <input
                id="set-v7"
                ref="set-v7"
                type="text"
              />
            </td>
          </tr>

          <tr class="qm-group-content">
            <td class="qm-key">白模+透视(0.1~0.9)</td>
            <td class="qm-value">
              <input
                id="set-ck1"
                ref="set-ck1"
                type="checkbox"
              /><input
                id="set-v8"
                ref="set-v8"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">旋转模型Y->Z</td>
            <td class="qm-value">
              <input
                id="set-ck7"
                ref="set-ck7"
                type="checkbox"
              />
            </td>
          </tr>
        </table>

        <div
          class="qm-button-font"
          id="property-set-save"
          ref="property-set-save"
        >
          保存
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>

    <!-- 以下是设备管理部分 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-extmodel"
      ref="property-extmodel"
      style="display: none; left: 5px; top: 400px; height: 400px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        设备库
      </div>

      <div class="qm-panel-body">
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divPlan"
          ref="divPlan"
        >
          <table
            class="qm-table"
            id="tableExtmodel"
            ref="tableExtmodel"
          >
            <tr class="qm-group-content">
              <td class="qm-key">设备名称</td>

              <td>操作</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>

    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-extmodel-dialog"
      ref="property-extmodel-dialog"
      style="display: none; right: 300px; top: 400px; width: 300px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        设备编辑
      </div>
      <div class="qm-panel-body">
        <table class="qm-table">
          <tr class="qm-group-content">
            <td class="qm-key">X轴位置</td>
            <td class="qm-value">
              <input
                id="ext-v1"
                ref="ext-v1"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">y轴位置</td>
            <td class="qm-value">
              <input
                id="ext-v2"
                ref="ext-v2"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">z轴位置</td>
            <td class="qm-value">
              <input
                id="ext-v3"
                ref="ext-v3"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">旋转角度(绕z轴)</td>
            <td class="qm-value">
              <input
                id="ext-v4"
                ref="ext-v4"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">旋转角度(绕y轴)</td>
            <td class="qm-value">
              <input
                id="ext-v5"
                ref="ext-v5"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">旋转角度(绕x轴)</td>
            <td class="qm-value">
              <input
                id="ext-v6"
                ref="ext-v6"
                type="text"
              />
            </td>
          </tr>
        </table>

        <div
          class="qm-button-font"
          id="property-extmodel-edit-save"
          ref="property-extmodel-edit-save"
        >
          保存
        </div>
        <div
          class="qm-button-font"
          id="property-extmodel-edit-open"
          ref="property-extmodel-edit-open"
        >
          开始编辑
        </div>
        <div
          class="qm-button-font"
          style="display: none"
          id="property-extmodel-edit-exit"
          ref="property-extmodel-edit-exit"
        >
          退出编辑
        </div>
        <div
          class="qm-button-font"
          id="property-extmodel-edit-close"
          ref="property-extmodel-edit-close"
        >
          关闭
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <!-- 以下是地图 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-map-dialog"
      ref="property-map-dialog"
      style="display: none; left: 30px; top: 10px"
    >
      <div class="qm-close"></div>

      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        定位地图
        <div
          class="qm-button-mini qmd-floor"
          style="position: absolute; z-index: 99"
          title="楼层"
        ></div>
      </div>

      <div
        class="qm-panel-body"
        style="width: 300px; height: 300px"
      >
        <canvas
          id="canvasmap"
          ref="canvasmap"
          style="width: 300px; height: 300px"
        ></canvas>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <!-- 以下是构件隔离窗口 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-component-dialog"
      ref="property-component-dialog"
      style="display: none; left: 30px; top: 10px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        构件隔离
      </div>

      <div class="qm-panel-body">
        <canvas
          id="canvascomponent"
          refd="canvascomponent"
          style="width: 300px; height: 300px"
        ></canvas>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <!-- 以下是计划部分 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-plan"
      ref="property-plan"
      style="display: none; left: 1px; top: 10px; width: 380px; height: 600px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        进度计划
      </div>
      <div
        class="qm-button-font"
        id="property-plan-add"
        ref="property-plan-add"
      >
        新增任务
      </div>
      <div
        class="qm-button-font"
        id="property-plan-play"
        ref="property-plan-play"
      >
        进度模拟
      </div>
      <div class="qm-panel-body">
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divPlan"
          ref="divPlan"
        >
          <table
            class="qm-table"
            id="tablePlan"
            ref="tablePlan"
          >
            <tr class="qm-group-content">
              <td class="qm-key">计划</td>
              <td class="qm-value">完成日期</td>
              <td style="width: 120px">操作</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-plan-dialog"
      ref="property-plan-dialog"
      style="display: none; left: 50%; transform: translate(-50%, -50%); top: 300px; width: 300px; height: 180px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        新增计划
      </div>
      <div class="qm-panel-body">
        <table class="qm-table">
          <tr class="qm-group-content">
            <td class="qm-key">计划名称</td>
            <td class="qm-value">
              <input
                id="inp1"
                ref="inp1"
                type="text"
              />
            </td>
          </tr>
          <tr class="qm-group-content">
            <td class="qm-key">计划完成日期</td>
            <td class="qm-value">
              <input
                id="inp2"
                ref="inp2"
                type="date"
                defaultValue="2020/07/01"
              />
            </td>
          </tr>
        </table>

        <div
          class="qm-button-font"
          id="property-plan-add-save"
          ref="property-plan-add-save"
        >
          保存
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <!-- 以下是视点管理 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-view"
      ref="property-view"
      style="display: none; left: 1px; top: 10px; width: 320px; height: 600px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        相机书签
      </div>
      <div
        class="qm-button-font"
        id="property-view-addpoint"
        ref="property-view-addpoint"
      >
        插入标记
      </div>
      <div
        class="qm-button-font"
        id="property-view-add"
        ref="property-view-add"
      >
        新增书签
      </div>
      <div
        class="qm-button-font"
        id="property-view-openAll"
        ref="property-view-openAll"
      >
        打开书签
      </div>
      <div class="qm-panel-body">
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divView"
          ref="divView"
        >
          <table
            class="qm-table"
            id="tableView"
            ref="tableView"
          >
            <tr class="qm-group-content">
              <td class="qm-key">名称</td>
              <td class="qm-value">二维码</td>
              <td style="width: 120px">操作</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-view-dialog"
      ref="property-view-dialog"
      style="display: none; left: 50%; transform: translate(-50%, -50%); top: 300px; width: 300px; height: 120px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        新增书签
      </div>
      <div class="qm-panel-body">
        <table class="qm-table">
          <tr class="qm-group-content">
            <td class="qm-key">书签名称</td>
            <td class="qm-value">
              <input
                id="inviewname"
                ref="inviewname"
                type="text"
              />
            </td>
          </tr>
        </table>

        <div
          class="qm-button-font"
          id="property-view-add-save"
          ref="property-view-add-save"
        >
          保存
        </div>
      </div>
      <!-- <div class="qm-resize"></div> -->
    </div>
    <!-- 以下是轴向剖切 -->
    <div
      class="qm-panel qm-section-panel qm-has-title"
      id="axiDiv"
      ref="axiDiv"
      style="right: 10px; bottom: 180px; width: 160px; height: 180px; margin-bottom: 0px; display: none"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        轴向剖切
      </div>
      <div class="qm-panel-body">
        <div class="qm-panel-header"></div>
        <div class="qm-panel-container qm-scroll-bar">
          <div
            class="qm-section-plane-head"
            style="height: 35px"
          >
            <div
              class="qm-button qmd-hide-slice iconfont icon-bim_gundongtiaozheng"
              title="隐藏"
            ></div>
          </div>
          <div class="qm-section-range">
            <div class="qm-range">
              <div>
                <p style="width: 10%; height: 10px; float: left; margin-top: 3px">Z</p>
                <ui-range
                  style="width: 90%"
                  id="axial-progress3"
                  ref="axial-progress3"
                  min="0"
                  max="100"
                  value="0, 100"
                  multiple
                ></ui-range>
              </div>
              <div>
                <p style="width: 10%; height: 10px; float: left; margin-top: 3px">Y</p>
                <ui-range
                  style="width: 90%"
                  id="axial-progress2"
                  ref="axial-progress2"
                  min="0"
                  max="100"
                  value="0, 100"
                  multiple
                ></ui-range>
              </div>
              <div>
                <p style="width: 10%; height: 10px; float: left; margin-top: 3px">X</p>
                <ui-range
                  style="width: 90%"
                  id="axial-progress1"
                  ref="axial-progress1"
                  min="0"
                  max="100"
                  value="0, 100"
                  multiple
                ></ui-range>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 以下是材质库 -->
    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="property-material"
      ref="property-material"
      style="display: none; left: 1px; top: 10px; width: 220px; height: 600px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        材质库
      </div>
      <div class="qm-panel-body">
        <div
          class="qm-panel-container qm-scroll-bar"
          id="divView"
          refd="divView"
        >
          <table
            class="qm-table"
            id="tableMaterials"
            ref="tableMaterials"
          >
            <tr class="qm-group-content"></tr>
          </table>
        </div>
      </div>
    </div>
    <!-- 以下是基本信息 -->
    <div
      class="qm-panel qm-has-title"
      id="modalInfoDiv"
      ref="modalInfoDiv"
      style="left: 50%; top: 300px; width: 300px; height: 210px; transform: translate(-50%, -50%); display: none"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        基本信息
      </div>
      <div class="qm-panel-body">
        <div class="qm-panel-header"></div>
        <div class="qm-panel-container qm-scroll-bar">
          <div class="qm-info">
            <ul class="qm-info-list">
              <li>
                构件数量：<span
                  class="qm-info-value"
                  id="infoCompCount"
                  ref="infoCompCount"
                  >26669</span
                >
              </li>
              <li>
                三角面数量：<span
                  class="qm-info-value"
                  id="infoAngleCount"
                  ref="infoAngleCount"
                  >6702792</span
                >
              </li>
              <li>
                顶点数量：<span
                  class="qm-info-value"
                  id="infoPointCount"
                  ref="infoPointCount"
                  >20108376</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 以下是测量信息 -->
    <div
      class="qm-panel qm-has-title"
      id="measureDiv"
      ref="measureDiv"
      style="right: 10px; top: 440px; width: 280px; height: 210px; display: none; z-index: 8"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        测量信息
      </div>
      <div class="qm-panel-body">
        <div class="qm-panel-header"></div>
        <div class="qm-panel-container qm-scroll-bar">
          <table
            class="qm-table"
            id="tableMeasure"
            ref="tableMeasure"
          >
            <tr class="qm-group-content">
              <td
                class="qm-value"
                style="width: 50px"
              >
                1
              </td>
              <td
                class="qm-value"
                style="width: 80px"
              >
                长度
              </td>
              <td
                class="qm-value"
                style="width: 50px"
              >
                X
              </td>
              <td
                class="qm-value"
                style="width: 50px"
              >
                Y
              </td>
              <td
                class="qm-value"
                style="width: 50px"
              >
                Z
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- 以下是右键菜单 -->
    <div
      class="contextmenu"
      id="context"
      ref="context"
    >
      <ul></ul>
    </div>

    <div
      class="contextmap"
      style="z-index: 12"
      id="context-map"
      ref="context-map"
    >
      <ul></ul>
    </div>

    <div
      class="qm-panel qm-has-title qm-sizable property-panel"
      id="div-modellist"
      ref="div-modellist"
      style="display: none; top: -50%; left: -50%; transform: translate(-50%, -45%); width: 1200px"
    >
      <div class="qm-close"></div>
      <div
        class="qm-title"
        style="cursor: move; user-select: none"
      >
        模型列表
      </div>
      <div class="qm-panel-body">
        <div class="dev-doc">
          <div
            class="w1400"
            style="max-height: 600px; overflow-y: auto"
          >
            <!-- <div style="font-size:32px;font-weight:600;line-height:45px;width:150px;margin-top: 40px;">案例模型</div> -->
            <ul
              class="list-doc2"
              id="model-container"
              ref="model-container"
            >
              <li>
                <img alt="from canvas" />
                <a>
                  <h3>技术分享</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100%; width: 20%; position: absolute; display: none">侧边栏</div>
  </div>
</template>

<script>
import ZyTitle from "../zy-title"
import "./bimjs/main.css"
import { QMViewer } from "./view.js"
var viewerDemo = null
var curQmodel = null
export default {
  name: "qmviewer",
  components: { ZyTitle },
  props: ["childMsg", "isCompont", "modelName", "forGis", "isLocal", "isSecond", "pubPath"],
  data() {
    return {
      //   viewerDemo: null,
      //   curQmodel: null,
    }
  },
  created() {
    this.initModel()
  },
  beforeRouteLeave(to, form, next) {
    window.removeEventListener("resize", this.handleResize, false)
    curQmodel.destory_qmodel()
    next()
  },
  methods: {
    initModel() {
      let _this = this
      this.$nextTick(function () {
        var qmodelobj = new QModelX.QModel()
        qmodelobj.refs = this.$refs
        var colorrgb = "rgb(51, 65, 87)"
        var colorrgba = "rgba(51, 65, 87,0.55)"
        qmodelobj.set_theme(colorrgb, colorrgba)
        //暴露到控制台一个qmodel对象，用于控制台的驱动接口程序。
        if (this.isSecond) {
          window.qmodel2 = qmodelobj
          qmodelobj.Name = "qm2"
        } else {
          window.qmodel = qmodelobj
          qmodelobj.Name = "qm1"
        }
        curQmodel = qmodelobj
        _this.$emit("callFather_before", curQmodel)
        viewerDemo = new QMViewer(curQmodel, this.forGis, this.pubPath)

        qmodelobj.viewerDemo = viewerDemo
        this.updateClientRects(false)
        if (this.forGis) {
          //  id=""
          var modeldiv = this.$refs["qmodel-div"]

          var cesiumdiv = document.getElementById("cesiumContainer")
          modeldiv.appendChild(cesiumdiv)

          var containerdiv = document.getElementById("container")
          containerdiv.style.pointerEvents = "none"
        }
        viewerDemo.on_model_loaded_callback = function () {
          //此时才算完成模型的加载，qmodel开始正常工作
          console.log("模型结构测试", curQmodel.get_model_tree())
          console.log("模型加载完成回调，可以获取模型的一些信息。比如相机的位置", curQmodel.get_camera_json())
          //其他回调可以在这时候写。
          if (!_this.isSecond) _this.initCallbackDemo()
          //重置视图
          viewerDemo.qmdobj.lookat_center()
          _this.$emit("callFather_loaded", curQmodel)
        }
        var model = this.modelName ? this.modelName : viewerDemo.getUrlParam("model")
        if (model) {
          if (this.isLocal) {
            model = "/" + model
            viewerDemo.loadModel(model, 5)
          } else viewerDemo.loadModel(model)
        } else {
          viewerDemo.InitModelList()
        }
        window.addEventListener("resize", this.handleResize, false)
      })
    },
    //在集成的时候这段代码可能要重写，跟每个框架中，qmodel_div是使用absolute布局的，跟父级的各种布局方式和嵌套有关
    //需要根据实际情况调整这段代码。
    updateClientRects(updateCanvas) {
      var modelRect = null
      var modeldiv = this.$refs["qmodel-div"].parentNode
      var pntdivrec = modeldiv.getClientRects()
      if (pntdivrec.length)
        modelRect = modelRect = {
          left: pntdivrec[0].left,
          top: pntdivrec[0].top,
          width: pntdivrec[0].width,
          height: pntdivrec[0].height,
        }
      else
        modelRect = {
          left: 0,
          top: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }

      //关于padding，需要偏移 比如本框架中，由el-main的样式产生了四边各20Px的偏移。
      var padding = 20
      if (this.isCompont) padding = 0
      modelRect.left = modelRect.left + padding
      modelRect.top = modelRect.top + padding
      modelRect.width = modelRect.width - padding
      modelRect.height = modelRect.height - padding
      viewerDemo.updateClientRects(modelRect, updateCanvas)
    },
    handleResize() {
      this.updateClientRects(true)
    },
    delayResize() {
      this.updateClientRects(true)
      setTimeout(() => {
        this.updateClientRects(true)
      }, 500) //有动效
    },
    initCallbackDemo() {
      const _this = this
      //点击示例：选中构件的ID：
      qmodel.set_mark_insert_callback(function (e) {
        console.log("demo--刚才插入的定位标记：", e)
      })
      //刚才点击的构件
      qmodel.set_select_callback(function (e) {
        console.log("demo--当前点击构件ID：", e)
        var cmpinfo = JSON.parse(e)
        //cmpinfo.cmpid是构件的id,id很有用，跟构件相关的接口都可以用
        qmodel.get_cmp_property_then(cmpinfo.cmpid).then((ptys) => {
          console.log("demo--构件的属性是：", ptys)
        })
        //获取构件的包围盒
        var box = qmodel.get_cmps_box3([cmpinfo.cmpid])
        console.log("demo--构件的包围盒：", box)
      })
    },
  },
}
</script>
<style></style>
